import { createRouter, createWebHistory } from 'vue-router'

import { $i18N } from '@/utility/util.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { i18n_title: 'pgtitle.home' },
    component: () => import ( /* webpackChunkName: "Home" */ '@/pages/Home.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name:'NotFound',
    meta: { i18n_title: 'pgtitle.notfound' },
    component: () => import ( /* webpackChunkName: "NotFound" */ '@/pages/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to) => {
  let { meta : { i18n_title = 'pgtitle.home' } } = to;
  document.title = $i18N(i18n_title);
})

export default router
