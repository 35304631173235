import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { $i18N, $link, $info } from '@/utility/util.js'

// vue config

// css
import './styles/base.css'

const app = createApp(App);
app.use(store).use(router).mount('#app');

app.config.globalProperties.$filters = {
    i18n(key, ...params) { return $i18N(key, ...params) }, 
    link(value) { return $link(value) }, 
    info(value) { return $info(value) }
  }
