import store from '@/store.js'

function getPropValue(type, key)
{
    return store.state[type][key]? store.state[type][key] : key;
}

export const $i18N = (key, ...params) => {
    let value = '';
    let searchKey = ('' + key).toLowerCase().replace(/_/g, '');
    
    if( store.state.i18n[searchKey] )
    {
        value = store.state.i18n[searchKey];
    }
    else
    {
        return key;
    }
    
    params.forEach((param, i) => {
        let regexp = new RegExp("\\$" + (i + 1), "g");
        value = value.replace(regexp, param);
    })
    return value;
}

export const $link = (key) => {
    return getPropValue('link', key);
}

export const $info = (key) => {
    return getPropValue('info', key);
}
